.flip-card {
  width: 200px;
  height: 300px;
  perspective: 1000px; /* Perspective for 3D flipping */
  margin: 10px;
}

.flip-card-inner {
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  position: relative;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border: 1px solid #ccc;
  border-radius: 20px;
  background: #f9f9f9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.671);
  box-sizing: border-box;
  text-align: center;
  padding: 5px;
}

.flip-card-front {
  background: #fff;
}

.flip-card-back {
  transform: rotateY(180deg);
  background: #ffffff; /* Optional: Different color for the back */
}
