a {
  text-decoration: none;
  color: inherit;
}

h2 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
}


.carousel-header {
  font-size: 2rem;
  margin-bottom: 30px;
  color: #333;
}

.carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
}

.carousel-item {
  width: 400px;
  overflow: hidden;
  text-align: center;
  border-radius: 20px;
  box-shadow: 0 10px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.carousel-item:hover {
  transform: scale(1.05);
}

.carousel-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-bottom: 2px solid #ddd;
}

.carousel-text {
  height: 150px;
  padding: 15px;
  color: white;
  text-decoration: none;
}

.carousel-text h3 {
  margin-bottom: 10px;
  font-size: 1.4rem;
  font-weight: bold;
  text-decoration: none;
}

.carousel-text p {
  font-size: 0.9rem;
  color: white;
  text-decoration: none;
}

.carousel-arrow {
  font-size: 2rem;
  color: white;
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease;
  user-select: none;
}

.carousel-arrow:hover {
  color: white;
}

.left-arrow {
  position: absolute;
  left: 15%;
}

.right-arrow {
  position: absolute;
  right: 15%;
}

.carousel-arrow:focus {
  outline: none;
}