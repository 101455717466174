.App {
  text-align: center;
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 10px;
}

section {
  align-content: center;
  position: relative;
  height: 100vh; /* Ensure it fills the viewport */
  overflow: hidden; /* Prevent the blob from overflowing */
}

body {
  margin: 0;
  padding: 0;
  min-height: 100%; /* Ensure the background covers the content height */
  background: url('./assets/static/images/background.jpg') no-repeat center center fixed; /* Centered and fixed */
  background-size: cover; /* Make the background cover the full page */
}

h2 {
  font-size: 3rem;
  color: #ffffff;
  margin-bottom: 0px;
  margin-top: 0px;
}

.content-wrapper-home {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;

  h1 {
    font-size: 3rem; /* Adjust size as needed */
    font-weight: bold;
    color: white;
    text-align: left;
    line-height: 1.2; /* Line spacing */
    margin-top: 15%; /* Add space above the h1 to move it down */
    margin-left: 15%; /* Space between h1 and p */
  }

  p {
    font-size: 1.25rem; /* Adjust for readability */
    color: #d3d3d3; /* Light gray */
    max-width: 35%; /* Keep text in a readable width */
    text-align: left;
    margin-left: 15%;
  }
}

.content-wrapper-about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  min-height: 100vh;
  text-align: center;
}

.content-wrapper-projects {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  text-align: center;
}

.content-wrapper-contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  min-height: 100vh;
}

.blob-container {
  position: absolute;
  top: 50%; /* Adjust this to move the blob further down */
  left: 75%; /* Adjust this to move the blob horizontally */
  transform: translate(-50%, -50%); /* Center the blob based on its top/left values */
  width: 150px; /* Adjust width as needed */
  height: 150px; /* Adjust height as needed */
  z-index: -1; /* Ensure it's behind other content */
}

.frosted-glass {
  position: absolute;
  top: 50%; /* Adjust this to move the blob further down */
  left: 75%; /* Adjust this to move the blob horizontally */
  transform: translate(-50%, -50%); /* Center the blob based on its top/left values */
  width: 30%;
  height: 45%;
  backdrop-filter: blur(10px); /* Frosted glass blur */
  background: rgba(255, 255, 255, 0.1); /* Semi-transparent white */
  border-radius: 20px; /* Rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Depth shadow */
  z-index: 1;

  /* Grainy texture effect */
  background-image: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.2) 1%,
    rgba(255, 255, 255, 0) 1%
  );
  background-size: 50px 50px;
  overflow: hidden;
}

.frosted-glass:hover {
  backdrop-filter: blur(0px); /* Keep some blur effect */
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.1) 100%, /* More transparent center */
    rgba(255, 255, 255, 0.1) 100% /* Slightly less transparent edges */
  );
  transition: backdrop-filter 1s ease, background 1s ease;
}

.about-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}