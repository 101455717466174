@keyframes bounce {
  0%, 100% {
    transform: translateY(0); /* Start at original position */
  }
  50% {
    transform: translateY(-3px); /* Move up slightly */
  }
}

#NavButtons:hover {
  text-decoration: underline;
}